// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Update the CSRF field on Formie forms
import formieCsrf from './components/formie-csrf';

// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

import {
    Splide,
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
    },

    data() {
        return {
            navActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.
        formieCsrf();

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        // Search focus state

        document.querySelector('[data-search-open]').addEventListener('click', (e) => {
            document.getElementById('search').focus();
        });


        // Filter functionality

        const replaceState = (params) => {
            if (params === '') {
                window.location.replace(`${location.protocol}//${location.host}${location.pathname}`);
                return;
            }

            window.location.replace(`${location.protocol}//${location.host}${location.pathname}?${params}`);
        };

        const practiceArea = document.getElementById('practiceArea-filter');

        if (practiceArea) {
            practiceArea.addEventListener('change', (event) => {
                const searchParams = new URLSearchParams(window.location.search);

                if (event.target.value == '') {
                    searchParams.delete('practiceArea');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('practiceArea', event.target.value);

                replaceState(searchParams.toString());
            });
        }

        const topic = document.getElementById('topic-filter');

        if (topic) {
            topic.addEventListener('change', (event) => {
                const searchParams = new URLSearchParams(window.location.search);

                if (event.target.value == '') {
                    searchParams.delete('topic');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('topic', event.target.value);

                replaceState(searchParams.toString());
            });
        }

        const locations = document.getElementById('location-filter');

        if (locations) {
            locations.addEventListener('change', (event) => {
                const searchParams = new URLSearchParams(window.location.search);

                if (event.target.value == '') {
                    searchParams.delete('locations');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('locations', event.target.value);

                replaceState(searchParams.toString());
            });
        }

        const keywordSearch = document.getElementById('team-keyword-search');

        if (keywordSearch) {
            const keywordBtn = document.querySelector('.js-keyword-btn');

            keywordBtn.addEventListener('click', (event) => {
                const searchParams = new URLSearchParams(window.location.search);

                if (keywordSearch.value == '') {
                    searchParams.delete('keyword');
                    replaceState(searchParams.toString());
                    return;
                }

                searchParams.set('keyword', keywordSearch.value);

                replaceState(searchParams.toString());
            });

            keywordSearch.addEventListener('keypress', (k) => {
                if (k.key === 'Enter') {
                    keywordBtn.click();
                }
            });
        }

        // Scroll to links

        const quickLink = document.querySelectorAll('.js-quick-link');

        if (quickLink.length) {
            document.addEventListener('click', (e) => {
                if (e.target.matches('.js-quick-link')) {
                    e.preventDefault();

                    const divId = e.target.getAttribute('href').split('#')[1];

                    document.getElementById(divId).scrollIntoView({
                        behavior: 'smooth',
                    });
                }
            });
        }
    },
}).mount('#app');
